<template lang="pug">
    <div>
        <router-view />
       
    </div>
</template>

<script>
export default {
  name: "layout",
};
</script>

<style scoped lang="less"></style>
